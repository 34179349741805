
import { Controller } from "stimulus"
import Swipe from 'swipejs';


export default class extends Controller {
  static targets = []

  connect() {
    this.initGallery();
    window.onresize = this.initGallery;

    // window.screen.width;
  }

  initGallery() {
    if (typeof window.swipeDesktop !== 'undefined') {
      window.swipeDesktop.kill()
    }

    if (typeof window.swipeMobile !== 'undefined') {
      window.swipeMobile.kill()
    }

    window.swipeDesktop = new Swipe(document.getElementById('slider'), {
      startSlide: 0,
      speed: 400,
      auto: false,
      draggable: true,
      continuous: true,
      disableScroll: false,
      stopPropagation: false,
      ignore: ".scroller",
      callback: function(index, elem, dir) { },
      transitionEnd: function(index, elem) { }
    }
    );
    window.swipeMobile = new Swipe(document.getElementById('slider2'), {
      startSlide: 0,
      speed: 400,
      auto: false,
      draggable: true,
      continuous: true,
      disableScroll: false,
      stopPropagation: false,
      ignore: ".scroller",
      callback: function(index, elem, dir) {
        // var items = document.getElementById("slider2").getElementsByClassName("item")
        var dots = document.getElementsByClassName("dot")
        for (let item of dots) {
          item.classList.remove("active")
        }
        dots.item(index).classList.add("active")
      },
      transitionEnd: function(index, elem) { }
    }
    );

  }

  next() {
    window.swipeDesktop.next()
  }

  prev() {
    window.swipeDesktop.prev()
  }
}