
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {


    window.addEventListener('scroll', function(ev) {

      var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      var child = document.getElementsByClassName("portrait ")[0]
      var portraits = document.getElementsByClassName("portrait")
      var arrayLength = portraits.length;
      for (var i = 0; i < arrayLength; i++) {
        if (scrollTop > 300) {
          portraits[i].classList.add("smile")
        } else {
          portraits[i].classList.remove("smile")
        }
        //Do something
      }

    });

  }


}