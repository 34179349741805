
import { Controller } from "stimulus"
import stickybits from 'stickybits'

export default class extends Controller {
  static targets = []

  connect() {
    stickybits('.child', { useStickyClasses: true });
  }


}